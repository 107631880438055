/* import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App; */

import React from 'react';
import { MsalProvider, useMsal } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import UserContactForm from './components/UserContactForm';

const msalInstance = new PublicClientApplication(msalConfig);

// Initialize the MSAL instance
msalInstance.initialize().then(() => {
  console.log("MSAL initialized");
}).catch((error) => {
  console.error("MSAL initialization failed", error);
});

function AppContent() {
  const { instance } = useMsal();

  return (
    <div className="App min-h-screen bg-gray-100 flex items-center justify-center">
      <UserContactForm msalInstance={instance} />
    </div>
  );
}

function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <AppContent />
    </MsalProvider>
  );
}

export default App;