import React, { useState, useEffect, useCallback, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import toast, { Toaster } from "react-hot-toast";
import logo from "../assets/7r_logo.png";

const UserContactForm = () => {
  const { instance, accounts, inProgress } = useMsal();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobilePhone: "",
    city: "",
    state: "",
    country: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const fileInputRef = useRef(null);

  const fetchUserData = useCallback(async () => {
    console.log("Fetching user data");
    if (accounts.length === 0) {
      console.log("No accounts, cannot fetch data");
      return;
    }

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    try {
      console.log("Acquiring token");
      const response = await instance.acquireTokenSilent(request);
      console.log("Token acquired, initializing graph client");
      const graphClient = getGraphClient(response.accessToken);
      console.log("Fetching user data from graph API");
      const user = await graphClient
        .api(
          "/me?$select=city,state,country,mobilePhone,givenName,surname,mail"
        )
        .get();
      console.log("User data fetched:", user);

      setFormData({
        firstName: user.givenName || "",
        lastName: user.surname || "",
        email: user.mail || user.userPrincipalName || "",
        mobilePhone: user.mobilePhone || "",
        city: user.city || "",
        state: user.state || "",
        country: user.country || "",
      });

      // Fetch user's profile picture
      try {
        console.log("Fetching user's profile picture");
        const photoBlob = await graphClient
          .api("/me/photo/$value")
          .responseType("blob")
          .get();

        setProfilePicture(URL.createObjectURL(photoBlob));
        console.log("Profile picture fetched successfully");
      } catch (photoError) {
        console.log(
          "No profile picture available or error fetching it:",
          photoError
        );
        setProfilePicture(null);
      }

      setIsLoading(false);
      console.log("Form data set, loading complete");
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to fetch user data. Please try again.");
      setIsLoading(false);
    }
  }, [accounts, instance]);

  useEffect(() => {
    console.log("Effect triggered.");
    console.log("Accounts:", accounts);
    console.log("InProgress status:", inProgress);

    const initializeAuth = async () => {
      if (accounts.length === 0 && inProgress === "none") {
        console.log("No accounts found, initiating login");
        try {
          await instance.loginRedirect(loginRequest);
        } catch (e) {
          console.error("Login failed:", e);
          toast.error("Failed to login. Please refresh and try again.");
          setIsLoading(false);
        }
      } else if (inProgress === "none") {
        console.log("Accounts found, fetching user data");
        fetchUserData();
      } else {
        console.log(`Authentication in progress: ${inProgress}`);
      }
    };

    initializeAuth();
  }, [accounts, inProgress, fetchUserData, instance]);

  useEffect(() => {
    return () => {
      if (profilePicture) {
        URL.revokeObjectURL(profilePicture);
      }
    };
  }, [profilePicture]);

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return phoneNumber;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobilePhone") {
      const formattedValue = formatPhoneNumber(value);
      setFormData((prevData) => ({
        ...prevData,
        [name]: formattedValue,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (accounts.length === 0) return;

    setIsUpdating(true);
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    try {
      const response = await instance.acquireTokenSilent(request);
      const graphClient = getGraphClient(response.accessToken);

      const updateData = {};
      if (formData.mobilePhone.trim()) {
        updateData.mobilePhone = formData.mobilePhone.trim();
        updateData.businessPhones = [formData.mobilePhone.trim()];
      }
      if (formData.city.trim()) updateData.city = formData.city.trim();
      if (formData.state.trim()) updateData.state = formData.state.trim();
      if (formData.country.trim()) updateData.country = formData.country.trim();

      if (Object.keys(updateData).length > 0) {
        await graphClient.api("/me").update(updateData);
        toast.success("Profile updated successfully");
        fetchUserData();
      } else {
        toast.info("No changes to update");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile. Please try again.");
    } finally {
      setIsUpdating(false);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check file size
      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > 4) {
        toast.error(
          "File size exceeds 4MB limit. Please choose a smaller file."
        );
        return;
      }

      try {
        setIsUpdating(true);
        const request = {
          ...loginRequest,
          account: accounts[0],
        };
        const response = await instance.acquireTokenSilent(request);
        const graphClient = getGraphClient(response.accessToken);

        // Upload the image
        await graphClient.api("/me/photo/$value").put(file);

        // Fetch the newly uploaded image
        const photoBlob = await graphClient
          .api("/me/photo/$value")
          .responseType("blob")
          .get();
        const newProfilePicture = URL.createObjectURL(photoBlob);

        // Update the state with the new profile picture
        setProfilePicture(newProfilePicture);
        toast.success("Profile picture updated successfully");
      } catch (error) {
        console.error("Error uploading profile picture:", error);
        if (error.statusCode === 400) {
          toast.error("File size too large. Please choose a file under 4MB.");
        } else {
          toast.error("Failed to upload profile picture. Please try again.");
        }
      } finally {
        setIsUpdating(false);
      }
    }
  };

  if (isLoading) {
    return (
      <div>
        Loading user data... (Accounts: {accounts.length}, InProgress:{" "}
        {inProgress})
      </div>
    );
  }

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <Card className="w-full max-w-2xl mx-auto">
        <CardHeader className="space-y-1">
          <div className="flex flex-col items-center mb-4">
            {profilePicture ? (
              <img
                src={profilePicture}
                alt="User Profile"
                className="h-24 w-24 rounded-full object-cover mb-4"
              />
            ) : (
              <div className="h-24 w-24 rounded-full bg-gray-200 flex items-center justify-center mb-4">
                <span className="text-2xl">
                  {formData.firstName.charAt(0)}
                  {formData.lastName.charAt(0)}
                </span>
              </div>
            )}
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              accept="image/*"
              style={{ display: "none" }}
            />
            <Button
              onClick={() => fileInputRef.current.click()}
              className="mt-2 bg-blue-500 hover:bg-blue-600 text-white"
              disabled={isUpdating}
            >
              {isUpdating ? "Uploading..." : "Change Profile Picture"}
            </Button>
            <img
               src={logo}
              alt="Company Logo"
              className="h-16 w-auto mt-4"
            />
          </div>
          <CardTitle className="text-2xl font-bold text-center">
            Update Contact Information
          </CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label htmlFor="firstName">First Name</Label>
                <Input
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  readOnly
                  className="bg-gray-100 text-gray-600 cursor-not-allowed"
                />
              </div>
              <div>
                <Label htmlFor="lastName">Last Name</Label>
                <Input
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  readOnly
                  className="bg-gray-100 text-gray-600 cursor-not-allowed"
                />
              </div>
            </div>

            <div>
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                name="email"
                value={formData.email}
                readOnly
                className="bg-gray-100 text-gray-600 cursor-not-allowed"
              />
            </div>

            <div>
              <Label htmlFor="mobilePhone">Mobile Phone</Label>
              <Input
                id="mobilePhone"
                name="mobilePhone"
                value={formData.mobilePhone}
                onChange={handleInputChange}
                placeholder="Enter mobile phone"
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label htmlFor="city">City</Label>
                <Input
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  placeholder="Enter city"
                />
              </div>
              <div>
                <Label htmlFor="state">State</Label>
                <Input
                  id="state"
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                  placeholder="Enter state"
                />
              </div>
            </div>

            <div>
              <Label htmlFor="country">Country</Label>
              <Input
                id="country"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                placeholder="Enter country"
              />
            </div>

            <Button
              type="submit"
              className="w-full bg-black hover:bg-gray-800 text-white"
              disabled={isUpdating}
            >
              {isUpdating ? "Updating..." : "Update Contact Information"}
            </Button>
          </form>
        </CardContent>
      </Card>
    </>
  );
};

export default UserContactForm;

function getGraphClient(accessToken) {
  return require("@microsoft/microsoft-graph-client").Client.init({
    authProvider: (done) => {
      done(null, accessToken);
    },
  });
}