import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    /* clientId: "dfb722c2-f824-4ebd-822e-0c390691aef8",
    authority:
      "https://login.microsoftonline.com/7701832a-2fc4-4d64-88aa-065610232620",
    redirectUri: "http://localhost:3000", */
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.info(message);
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ["User.Read", "User.ReadWrite", "User.ReadWrite.All", "APIConnectors.Read.All", "APIConnectors.ReadWrite.All" ],
};
